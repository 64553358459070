<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>
<script>
export default {
  name:"App",
  data(){
    return{
      loading:true,
    }
  },
  methods:{
    getLoadingStatus(){
      
    }
  }
}
</script>
<style lang="scss">

</style>
