import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/views/layout/Layout"),
        children: [
            {
                path: 'services-type',
                name: 'services-type',
                component: () => import('@/views/pages/services/ServiceType')
            },
            {
                path: 'services',
                name: 'services',
                component: () => import('@/views/pages/services/Services')
            },
            {
                path: "app",
                component: () => import("@/views/layout/AppLayout"),
                children: [
                    {
                        path: "service-inspection",
                        name: "service-inspection",
                        component: () => import("@/views/pages/Service-Inspection"),
                    },
                    {
                        path: "cod-order-place",
                        name: "cod-order-place",
                        component: () => import("@/views/pages/Cod-Order-Place"),
                    },
                    {
                        path: "iu-order-place",
                        name: "iu-order-place",
                        component: () => import("@/views/pages/IU-Order-Place"),
                    },
                    {
                        path: "order-completed",
                        name: "order-completed",
                        component: () => import("@/views/pages/components/PaymentSuccess"),
                    },
                ]
            }, 
            {
                path: 'customers',
                name: 'customers',
                component: () => import('@/views/pages/customers/Customer')
            },
            {
                path: 'vehicles',
                name: 'vehicles', 
                component: () => import('@/views/pages/vehicles/Vehicles')
            },          
            {
                path: 'orders',
                name: 'orders',
                component: () => import('@/views/pages/orders/Orders')
            },  
            {
                path: 'orders/order-details/:id',
                name: 'order-details',
                component: () => import('@/views/pages/orders/OrderDetail')
            },
            {
                path: 'vehicle-details/:id',
                name: 'vehicle-details',
                component: () => import('@/views/pages/vehicles/VehicleDetails')
            },
            {
                path: 'inspection-details/:vehnO/:id',
                name: 'inspection-details',
                component: () => import('@/views/pages/vehicles/InspectionDetails')
            },
            {
                path: 'test-details/:vehnO/:id/:module',
                name: 'test-details',
                component: () => import('@/views/pages/vehicles/TestDetails')
            },
            {
                path: 're-conciliation',
                name: 're-conciliation',
                component: () => import('@/views/pages/report/Reconsiliation')
            },
            {
                path: 'hold-orders',
                name: 'hold-orders',
                component: () => import('@/views/pages/hold-order/HoldOrders')
            },           
            {
                path: 'hold-order-details/:id',
                name: 'hold-order-details',
                component: () => import('@/views/pages/hold-order/HoldOrderDetail')
            },          
            {
                path: 'invoice-quotation-payment',
                name: 'invoice-quotation-payment',
                component: () => import('@/views/pages/components/QuotationInvoicePaymentMode')
            },           
            {
                path: 'invoice-payment',
                name: 'invoice-payment',
                component: () => import('@/views/pages/components/InvoicePaymentListing')
            },           
            {
                path: 'invoice-payment/create',
                name: 'invoice-payment-create',
                component: () => import('@/views/pages/components/InvoicePaymentCreate')
            },           
            {
                path: 'invoice-payment/detail/:Id',
                name: 'invoice-payment-detail',
                component: () => import('@/views/pages/components/InvoicePaymentDetail')
            },           
            {
                path: 'quotation-payment',
                name: 'quotation-payment',
                component: () => import('@/views/pages/components/QuotationPaymentListing')
            },           
            {
                path: 'quotation-payment/create',
                name: 'quotation-payment-create',
                component: () => import('@/views/pages/components/QuotationPaymentCreate')
            },           
            {
                path: 'quotation-payment/detail/:Id',
                name: 'quotation-payment-detail',
                component: () => import('@/views/pages/components/QuotationPaymentDetail')
            },           
            {
                path: 'discount',
                name: 'discount',
                component: () => import('@/views/pages/components/Discount')
            },           
            {
                path: 'top-up-wallet',
                name: 'top-up-wallet',
                component: () => import('@/views/pages/components/TopUpWallet')
            },           
            {
                path: 'top-up-wallet-payment',
                name: 'top-up-wallet-payment',
                component: () => import('@/views/pages/EwalletTopupPayment')
            },           
            {
                path: 'iu-pending',
                name: 'iu-pending',
                component: () => import('@/views/pages/components/IU-Listing')
            },           
            {
                path: 'tags',
                name: 'tags',
                component: () => import('@/views/pages/tag/TagListing')
            }, 
            {
                path: 'cashback',
                name: 'cashback',
                component: () => import('@/views/pages/cashback/Cashback')
            },
            {
                path: 'create-cashback',
                name: 'createCashback',
                component: () => import('@/views/pages/cashback/Create-Cashback')
            },
            {
                path: 'cashback-payment',
                name: 'cashbackPayment',
                component: () => import('@/views/pages/cashback/CashbackPayment')
            },
            {
                path: 'closing-report',
                name: 'closingReport',
                component: () => import('@/views/pages/report/ClosingReport')
            },
            {
                path: 'activity-logs',
                name: 'activitylogs',
                component: () => import('@/views/pages/activityLog/activityLogs')
            },
            {
                path: 'nets-details',
                name: 'netsDetails',
                component: () => import('@/views/pages/components/NetsDetails')
            },  
        ]
    },
    {
        name: "authenticate",
        path: "/",
        component: () => import("@/views/pages/auth/Authenticate"),
        children: [
            {
                path: "login",
                name: "login",
                component: () => import("@/views/pages/auth/Login"),
            },
        ],
    },
    {
        path: "*",
        redirect: "/404",
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/pages/error/Error"),
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
